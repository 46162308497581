<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style lang="scss">
.f-b {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.f-s {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.f-c-c {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
#app {
  margin: 0;
  padding: 0;
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
</style>
