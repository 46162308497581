<template>
  <div>
    <div v-if="(details.title && details.ui_id == 1)">
      <img class="cover" :src="details.banner_list" alt="" />
      <div class="price">￥{{ details.price }}</div>
      <div class="title">{{ details.title }}</div>
      <div class="describe">{{ details.describe }}</div>
      <div class="f-b more">
        <div>非实物</div>
        <div>已售{{ details.sold }}件</div>
      </div>
      <div class="line"></div>
      <div class="f-b comment" @click="commentsTap">
        <div>评价（{{ commentsList.length }}）</div>
        <img :src="arrow" alt="" />
      </div>
      <div v-for="(item, index) in commentsList" :key="index">
        <div v-if="index < 2" class="comment-list">
          <div class="f-s">
            <img class="avatar" :src="item.avatar" alt="" />
            <div class="name">{{ item.name }}</div>
          </div>
          <div class="comment-content">{{ item.content }}</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="comment">商品详情</div>
      <img class="cover details" :src="details.details" alt="" />
      <div v-if="!dialogVisible" class="send" @click="sendTap">提交信息</div>

      <!-- <div v-if="show" class="pop-view">
      <div class="pop-view-cancel" @click="cancelTap"></div>
      <div class="pop-view-input">
        <div class="pop-view-input-item">
          <div class="f-s">
            <div class="pop-view-input-title">您的姓名</div>
            <div class="pop-view-input-title-tip">*</div>
          </div>
          <input class="pop-view-input-text" v-model="name" type="text" placeholder="请输入您的姓名" />
        </div>
        <div class="pop-view-input-item">
          <div class="f-s">
            <div class="pop-view-input-title">您的手机号</div>
            <div class="pop-view-input-title-tip">*</div>
          </div>
          <input class="pop-view-input-text" v-model="phone" type="text" placeholder="请输入您的手机号" />
        </div>
      </div>
      <div class="pop-view-pay" @click="payTap">确认信息并购买</div>
    </div> -->
    </div>
    <div v-if="(details.title && details.ui_id == 2)">
      <img class="cover" :src="details.banner_list" alt="" />
      <div class="c-section">
        <div>{{ details.price }}元{{ details.title }}</div>
        <div class="c-section-join" @click="sendTap">立即参加</div>
      </div>
      <img class="cover" :src="details.details" alt="" />
      <div class="c-section">
        <div>{{ details.price }}元{{ details.title }}</div>
        <div class="c-section-join" @click="sendTap">立即参加</div>
      </div>
      <img class="cover" src="../assets/b_tap.png" alt="" @click="sendTap">
      <div class="c-section c-bottom">
        <div>{{ details.price }}元{{ details.title }}</div>
        <div class="c-section-join" @click="sendTap">立即参加</div>
      </div>
      <div v-if="details.company == 2" class="c-b-tip">
        <div>观心诊所</div>
        <div>粤（A）广（2022）第481号</div>
      </div>
    </div>

    <el-dialog title="提交信息" v-model="dialogVisible" width="90%">
      <span>
        <div class="pop-view-input-title">您的姓名</div>
        <el-input v-model="name" class="pop-view-input-text" placeholder="请输入您的姓名"></el-input>
      </span>
      <span>
        <div class="pop-view-input-title">您的手机号</div>
        <el-input v-model="phone" class="pop-view-input-text" placeholder="请输入您的手机号"></el-input>
      </span>
      <div class="dialog-footer">
        <el-button class="send-btn" @click="dialogVisible = false">取 消</el-button>
        <el-button class="send-btn" type="primary" @click="payTap">提交信息并购买</el-button>
      </div>
    </el-dialog>

    <el-dialog title="提交信息" v-model="dialogVisible" width="90%">
      <span>
        <div class="pop-view-input-title">您的姓名</div>
        <el-input v-model="name" class="pop-view-input-text" placeholder="请输入您的姓名"></el-input>
      </span>
      <span>
        <div class="pop-view-input-title">您的手机号</div>
        <el-input v-model="phone" class="pop-view-input-text" placeholder="请输入您的手机号"></el-input>
      </span>
      <div class="dialog-footer">
        <el-button class="send-btn" @click="dialogVisible = false">取 消</el-button>
        <el-button class="send-btn" type="primary" @click="payTap">提交信息并购买</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
var wx = require('jweixin-module')
// import MonitorKeyboard from '@/utils/monitorKeyboard.js'
export default {
  name: "HomeView",
  data() {
    return {
      dialogVisible: false,
      code: '',
      order_id: "",
      name: "",
      phone: "",
      show: false,
      arrow:
        "https://guanxin-1305847118.cos.ap-nanjing.myqcloud.com/f7b4ae7a656a3b2779743227c8ea8f06.jpg",
      details: {
        describe: "",
        banner_list: "",
        details: "",
        id: 3,
        jl_creativetype: "",
        price: "",
        sold: "",
        status: 0,
        title: "",
        tm_short_url: "",
        isFrom: ''
      },
      commentsList: [],
    };
  },

  created() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      if (this.$route.query.code) {
        if (this.$route.query.status == 'overdue') {
          this.isFrom = this.$route.query.status
          this.code = this.$route.query.code
          this.name = this.$route.query.name
          this.phone = this.$route.query.phone
          this.details.price = this.$route.query.price
          this.details.title = this.$route.query.title
          this.getOriDate();
          this.commentsDate();
          // this.startInterval();
          // this.payTap()
        } else {
          console.log('有code')
          this.code = this.$route.query.code
          this.getOriDate();
          this.commentsDate();
          // this.startInterval();
        }
      } else {
        console.log('没有code')
        var redirect_uri = `https://weimeng.page.guanxinlab.com?id=${this.$route.query.id}&status=${this.$route.query.status}&clickid=${this.$route.query.clickid}&click_id=${this.$route.query.gdt_vid.length?this.$route.query.gdt_vid:this.$route.query.qz_gdt}`
        var url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcf7ed57ce9f1f8ff&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
        location.href = url
        // this.$store.dispatch('user/getOpenId', window.location.href.split('?')[1].split('&')[0].split('=')[1])
      }

    } else {// 浏览器
      console.log(this.$route.query, '浏览器', this.$route.query.id);
      this.getOriDate();
      this.commentsDate();
      this.reportDate("VIEW_CONTENT");
      this.startInterval();

    }

    if (this.$route.query.status == 'comments') {
      console.log('tanchukuang')
      this.sendTap()
    }
  },

  methods: {
    commentsTap() {
      this.$router.push({
        name: "comments",
        query: {
          id: this.$route.query.id,
          name: this.name,
          phone: this.phone,
          title: this.details.title,
          price: this.details.price,
          order_id: this.$route.query.order_id,
          clickid: this.$route.query.clickid,
          click_id: this.$route.query.click_id,
        },
      });

      // this.$router.push({
      //   name: "done",
      //   query: {url:this.details.tm_short_url},
      // });
    },
    payTap() {
      if (!this.name) {
        this.$message({
          message: '请输入姓名',
          type: 'error'
        });
        return;
      }

      var res = /^[1][3,4,5,6.7,8,9][0-9]{9}$/
      if (!res.test(this.phone)) {
        this.$message({
          message: '请输入正确手机号',
          type: 'error'
        });
        return;
      }
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        // if (this.$route.query.code) {
        console.log('gzh-pay')
        this.payWxDate();
      }
      else {
        this.payDate();
        console.log('没有code', this.code = this.$route.query.code)
      }
    },
    cancelTap() {
      this.show = false;
    },
    showPopup() {
      console.log("展示弹出层");
      this.show = true;
    },

    // 上报
    reportDate(action_type) {
      // let queryd = this.$route.query;
      console.log('details', this.details)
      var data = {
        click_id: this.$route.query.click_id,
        to_type: this.details.company,
        company: this.details.company,
        type: 'WEB',
        action_type: action_type,
      };
      let url = `https://h5api.guanxinlab.com/weimeng/user_actions/add`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          return res.json();
        })
        .then(() => {
          // console.log("默认get请求", res);
        });
    },

    // 评论
    commentsDate() {
      fetch("https://h5api.guanxinlab.com/comments-list")
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          this.commentsList = res.data;
          console.log("评论", this.commentsList);
        });
    },

    // 信息
    getOriDate() {
      fetch(
        `https://h5api.guanxinlab.com/landing-page?id=${this.$route.query.id}`
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          this.details = res.data;
          console.log(this.details);
          this.reportDate("VIEW_CONTENT");

          // console.log("默认get请求", res);
        });
    },

    // 支付
    payDate() {
      let loading = this.$loading({
        lock: true,
        text: '正在支付',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      })
      var data = {
        type: 2,
        phone: this.phone,
        name: this.name,
        price: this.details.price,
        product_name: this.details.title,
      };

      fetch("https://api.gxapp.guanxinlab.com/pay/h5", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          loading.close();
          this.$router.replace({
            name: "home",
            query: {
              click_id: this.$route.query.click_id,
              to_type: this.details.company,
              id: this.$route.query.id,
              name: this.name,
              phone: this.phone,
              order_id: res.data.order_id,
            },
          });
          this.order_id = res.data.order_id;
          location.href = res.data.mweb_url;
        });
    },

    // 公众号支付
    payWxDate: function () {
      let loading = this.$loading({
        lock: true,
        text: '正在支付',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      })
      var that = this
      var data = {
        type: 2,
        code: this.code,
        phone: this.phone,
        name: this.name,
        price: this.details.price,
        product_name: this.details.title,
      };
      console.log('rucan ', data)

      fetch("https://api.gxapp.guanxinlab.com/pay/jspay", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          console.log(res)
          if (res.code == 0) {
            this.order_id = res.data.order_id
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: 'wxcf7ed57ce9f1f8ff', // 必填，公众号的唯一标识
              timestamp: res.data.timeStamp, // 必填，生成签名的时间戳
              nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
              signature: res.data.paySign, // 必填，签名
              jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
            })
            wx.ready(function () {
              wx.chooseWXPay({
                timestamp: res.data.timeStamp, // 时间戳
                nonceStr: res.data.nonceStr, // 随机数
                package: res.data.package, //
                signType: res.data.signType,
                paySign: res.data.paySign, // 签名
                success: function () {
                  // alert('支付成功')
                  loading.close();
                  that.$router.replace({
                    name: "done",
                    query: {
                      click_id: that.$route.query.click_id,
                      to_type: that.details.company ? that.details.company : that.$route.query.to_type,
                      url: that.details.tm_short_url,
                    },
                  });
                  // window.location.href = window.location.href + '&orderId=' + res.data.order_id
                },
                cancel: function () {
                  loading.close();
                  var redirect_uri = `https://weimeng.page.guanxinlab.com?id=${that.$route.query.id}&status=overdue&phone=${that.phone}&name=${that.name}&price=${that.details.price}&product_name=${that.details.title}&order_id=''&to_type=${that.details.company ? that.details.company : that.$route.query.to_type}&click_id=${that.$route.query.click_id}`
                  var url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcf7ed57ce9f1f8ff&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
                  location.href = url
                  // alert('支付取消')
                },
                fail: function () {
                  loading.close();
                  alert('支付失败')
                }
              })
            })
          } else {
            // alert(this.code + '-' + res.code)
            // if (this.isFrom != 'overdue') {
            // var redirect_uri = `https://weimeng.page.guanxinlab.com?id=${this.$route.query.id}&status=overdue&phone=${this.phone}&name=${this.name}&price=${this.details.price}&product_name=${this.details.title}&order_id=''`
            // var url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcf7ed57ce9f1f8ff&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
            // location.href = url
            // } else {
            // this.isFrom = ''
            // alert(this.code + '-' + res.code)
            // }
            // console.log('code过期')
          }
        });
    },

    // 启动定时任务
    startInterval() {
      if (this.timer) {
        // clearInterval(this.timer);
      } else {
        this.timer = setInterval(() => {
          if (this.$route.query.order_id) {
            this.to_type = this.$route.query.to_type;
            this.phone = this.$route.query.phone;
            this.name = this.$route.query.name;
            this.order_id = this.$route.query.order_id;
          }
          if (this.order_id) {
            this.pullOriDate();
          }
          console.log("定时", this.order_id);
        }, 1000);
      }
    },
    // 支付状态
    pullOriDate() {
      console.log("order_id", this.$route.query.order_id);
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        // if (this.$route.query.code) {
        console.log('gzh-pay')
        this.gzhState();
        // }
        // else {
        //   this.browserState();
        // }
      } else {// 浏览器
        this.browserState();
      }
    },

    // 浏览器支付状态
    browserState() {

      fetch(
        `https://api.gxapp.guanxinlab.com/pay/h5/check?order_id=${this.order_id}`
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.data.trade_state == "SUCCESS") {
            this.$router.replace({
              name: "done",
              query: {
                click_id: this.$route.query.click_id,
                to_type: this.details.company ? this.details.company : this.$route.query.to_type,
                clickid: this.$route.query.clickid,
                url: this.details.tm_short_url,
              },
            });
          }

          if (res.data.trade_state == "NOTPAY") {
            // clearInterval(this.timer);
            // this.timer = null;
            // this.$router.replace({
            //   name: "nopay",
            //   query: {
            //     id: this.$route.query.id,
            //     name: this.name,
            //     phone: this.phone,
            //     order_id: this.order_id,
            //     code: this.$route.query.code
            //   },
            // });
          }
          // console.log("默认get请求", res);
        });
    },

    // 微信公众号支付状态
    gzhState() {
      fetch(
        `https://api.gxapp.guanxinlab.com/pay/js/check?order_id=${this.order_id}`
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          console.log('公众号支付状态', res)
          if (res.data.trade_state == "SUCCESS") {
            this.$router.replace({
              name: "done",
              query: {
                click_id: this.$route.query.click_id,
                to_type: this.details.company,
                url: this.details.tm_short_url,
              },
            });
          }

          if (res.data.trade_state == "NOTPAY") {
            console.log('订单未支付')
            // clearInterval(this.timer);
            // this.timer = null;
            // this.$router.replace({
            //   name: "nopay",
            //   query: {
            //     id: this.$route.query.id,
            //     name: this.name,
            //     phone: this.phone,
            //     order_id: this.order_id,
            //     code: this.$route.query.code,
            //   },
            // });
          }
        });
    },

    sendTap() {
      this.dialogVisible = true
      // this.showPopup();
    },

    // 监听键盘
    // getKeyboardState() {
    //   this.monitorKeyboard = new MonitorKeyboard();
    //   this.monitorKeyboard.onStart();

    //   // 监听虚拟键盘弹出事件
    //   this.monitorKeyboard.onShow(() => {
    //     console.log('键盘弹出')
    //   })

    //   //监听键盘收起的事件
    //   this.monitorKeyboard.onHidden(() => {
    //     console.log('键盘收起')
    //   })
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* @import '../../assets/css/book/style.css'; */

.cover {
  width: 100%;
}

.price {
  font-size: 24px;
  color: rgb(255, 38, 73);
  font-weight: 500;
  padding: 15px 0 0px 0;
}

.title {
  font-size: 22px;
  font-weight: 500;
  padding: 10px 5px 0 5px;
}

.describe {
  font-weight: 600;
  padding-top: 10px;
  font-size: 20px;
}

.more {
  font-size: 15px;
  padding: 10px 5px;
}

.line {
  width: 100%;
  height: 10px;
  background-color: #f5f5f5;
}

.comment {
  font-weight: 500;
  padding: 10px 5px;
}

.comment img {
  width: 25px;
  height: 25px;
}

.comment-list {
  padding: 5px;
}

.avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.name {
  margin-left: 10px;
}

.comment-content {
  padding: 10px 0 5px 0;
}

.details {
  padding-bottom: 50px;
}

.send {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  /* height: 54px; */
  padding: 15px;
  background-color: #fe2c55;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
}

.pop-view {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  /* bottom: 0;/ */
  z-index: 999;
}

.pop-view-pay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background-color: #fe2c55;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
}

.pop-view-cancel {
  bottom: 200px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.2);
}

.pop-view-input {
  padding: 15px;
  position: absolute;
  left: 0;
  bottom: 52px;
  right: 0;
  background-color: #ffffff;
}

.pop-view-input input {
  font-size: 20px;
  padding: 15px 0 10px 0;
  border: 0;
}

.pop-view-input-title {
  color: #6a6a6a;
  font-size: 20px;
  font-weight: 500;
}

.pop-view-input-title-tip {
  color: #fe2c55;
}

.pop-view-input-item {
  /* margin-bottom: 20px; */
  padding-top: 10px;
  border-bottom: 1px solid #dcdfe6;
}

.pop-view-input-text {
  font-size: 20px !important;
  padding: 10px 0;
  /* height: 80px !important; */
  width: 100%;
}

.dialog-footer {
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.send-btn {
  font-size: 18px;
}

.c-section {
  font-weight: bold;
  margin-top: -5px;
  color: #FFF;
  padding: 10px 15px;
  background: linear-gradient(0deg, #f19043 0%, #ecd76a 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.c-section-join {
  font-weight: bold;
  font-size: 17px;
  padding: 5px 15px;
  color: #f19043;
  border-radius: 17px;
  background-color: #FFF;
}

.c-bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.c-b-tip {
  font-weight: 100;
  font-size: 10px;
  justify-content: center;
  padding: 10px 0 55px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #666;
}
</style>
